<template>
  <v-list density="comfortable">
    <side-menu-item v-for="menuItem in primaryMenuList" :key="menuItem.title" :rail="rail" :menu-item="menuItem" />

    <side-menu-item v-if="hasSharedAssets" :rail="rail" :menu-item="sharedMenuItem" />

    <v-divider class="my-2" />

    <side-menu-item v-for="menuItem in secondaryMenuList" :key="menuItem.title" :rail="rail" :menu-item="menuItem" />
  </v-list>
</template>

<script setup lang="ts">
import type { MenuItem } from "./SideMenuItem.vue"

defineProps<{ rail?: boolean }>()

const assetCategoryStore = useAssetCategoryStore()
const sharedStore = useSharedStore()
const authStore = useAuthStore()
const settingsStore = useSettingsStore()
const supplierStore = useSupplierStore()

const { isSuperAdmin, currentOrganization } = storeToRefs(authStore)
const { dataQualityModuleEnabled } = storeToRefs(settingsStore)
const { isLoggedInOnSupplier, isSupplierPlanner, isSupplierManager } = storeToRefs(supplierStore)

const { hasOrganizationWithScope } = authStore

const { data: assetCategories } = useLazyAsyncData(
  `used-categories:${currentOrganization.value}`,
  async () => {
    return isLoggedInOnSupplier.value || !currentOrganization.value ? [] : await assetCategoryStore.getUsedCategories()
  },
  {
    watch: [currentOrganization],
    default: () => [] as Array<AssetCategory>,
    immediate: true,
  },
)

const { data: hasSharedAssets } = useLazyAsyncData("has-shared-assets", () => sharedStore.hasSharedAssets(), { default: () => false })

const showSupplierAdmin = computed(() => {
  // Check if supplier is logged in to his own supplier and has the supplier module enabled
  if (!isLoggedInOnSupplier.value) {
    return false
  }

  if (isSuperAdmin.value) {
    return true
  }

  // Check if supplier user is manager or planner
  return isSupplierPlanner.value || isSupplierManager.value
})

const primaryMenuList = computed<Array<MenuItem>>(() => [
  {
    icon: "dashboard.icon",
    title: "dashboard.title",
    to: "/dashboard",
  },
  {
    icon: "assets.icon",
    title: "assets.title",
    exact: true,
    to: `/assets?tab=LIST&filters=${encodeURIComponent(JSON.stringify({ "category.description": [] }))}`,
    ...(!isLoggedInOnSupplier.value && {
      children: assetCategories.value!.map((category) => ({
        iconLabel: category.marker.description,
        color: category.marker.color,
        title: category.description,
        roundedAvatar: true,
        to: `/assets?tab=LIST&filters=${encodeURIComponent(JSON.stringify({ "category.description": [category.description] }))}`,
      })),
    }),
  },
  {
    icon: "tickets.icon",
    title: "tickets.title",
    exact: true,
    to: `/tickets?filters=${encodeURIComponent(JSON.stringify({ type: [] }))}`,
    disabled: [
      hasOrganizationWithScope(AuthScope.CAN_VIEW_INSPECTIONS),
      hasOrganizationWithScope(AuthScope.CAN_VIEW_MALFUNCTIONS),
      hasOrganizationWithScope(AuthScope.CAN_VIEW_ACTIONS),
    ].every((hasAccess) => !hasAccess),
    children: [
      {
        icon: "inspections.icon",
        color: "inspections.color",
        title: "inspections.title",
        to: `/tickets/inspections`,
        disabled: !hasOrganizationWithScope(AuthScope.CAN_VIEW_INSPECTIONS),
      },
      {
        icon: "malfunctions.icon",
        color: "malfunctions.color",
        title: "malfunctions.title",
        to: `/tickets/malfunctions`,
        disabled: !hasOrganizationWithScope(AuthScope.CAN_VIEW_MALFUNCTIONS),
      },
      {
        icon: "actions.icon",
        color: "actions.color",
        title: "actions.title",
        to: `/tickets/actions`,
        disabled: !hasOrganizationWithScope(AuthScope.CAN_VIEW_ACTIONS),
      },
    ],
  },
  {
    icon: "finance.icon",
    title: "finance.title",
    to: "/finance",
    hideDropdownTitle: true,
    children: [
      {
        title: "finance.myp.title",
        to: "/finance/myp",
        disabled: !hasOrganizationWithScope(AuthScope.CAN_VIEW_FINANCES_BUDGET_OVERVIEW),
      },
      {
        title: "finance.tickets.title",
        to: "/finance/tickets",
        disabled: !hasOrganizationWithScope(AuthScope.CAN_VIEW_FINANCES_COSTS_AND_LABOUR_OVERVIEW),
      },
      {
        title: "finance.contracts.title",
        to: "/finance/contracts",
      },
      ...(showSupplierAdmin.value
        ? [
            {
              title: "finance.costSpecifications.title",
              to: "/finance/cost-specifications",
            },
            {
              title: "contracts.priceLists.title",
              to: "/finance/supplier-price-lists",
            },
          ]
        : []),
    ],
  },
  {
    icon: "analytics.icon",
    title: "analytics.title",
    to: "/analytics",
    hideDropdownTitle: true,
    children: [
      {
        title: "risk.analysis",
        to: "/analytics/risk",
        disabled: !hasOrganizationWithScope(AuthScope.CAN_VIEW_ANALYSIS_RISK_OVERVIEW),
      },
      {
        title: "inspections.analysis",
        to: "/analytics/inspections",
        disabled: !hasOrganizationWithScope(AuthScope.CAN_VIEW_ANALYSIS_INSPECTION_OVERVIEW),
      },
      ...(dataQualityModuleEnabled.value
        ? [
            {
              title: "analytics.dataQuality.title",
              to: "/analytics/data-quality",
              disabled: !hasOrganizationWithScope(AuthScope.ADMIN),
            },
          ]
        : []),
    ],
  },
  {
    icon: "overviews.icon",
    title: "overviews.title",
    to: "/overviews",
  },
  ...(isLoggedInOnSupplier.value
    ? [
        {
          icon: "$organization",
          title: "organizations",
          to: "/organizations",
        },
      ]
    : []),
])

const sharedMenuItem: MenuItem = {
  icon: "shared.icon",
  title: "shared.title",
  to: "/shared",
  hideDropdownTitle: true,
  children: [
    {
      title: "shared.assets.title",
      to: "/shared/assets",
    },
    {
      title: "shared.tickets.title",
      to: "/shared/tickets",
    },
  ],
}

const secondaryMenuList = computed<Array<MenuItem>>(() => [
  {
    icon: "support.icon",
    title: "support.title",
    to: "/support",
  },
  {
    icon: "settings.icon",
    title: "settings.title",
    to: "/settings",
  },
  ...(hasOrganizationWithScope(AuthScope.CAN_VIEW_CHANGELOGS_OVERVIEW)
    ? [
        {
          icon: "mutationLog.icon",
          title: "mutationLog.title",
          to: "/mutation-log",
        },
      ]
    : []),
])
</script>
